const companiesSectionData = {
  typeOptions: [
    'Company', 'Trust',
  ],

  ownershipOptions: [
    'Joint', 'Joint with someone else',
  ],
};

const loanSectionData = {
  lendingPurposeOptions: [
    {
      text: 'New Purchase',
      value: 'New Purchase',
    },
    {
      text: 'Consolidate Debt',
      value: 'Consolidate Debt',
    },
    {
      text: 'Refinance',
      value: 'Refinance',
    },
    {
      text: 'Top up',
      value: 'Top up',
    },
    {
      text: 'Business Loan',
      value: 'Business Loan',
    },
  ],

  propertyToPurchaseOptions: [
    {
      text: 'Yes',
      value: 'Yes',
    },
    {
      text: 'No',
      value: 'No',
    },
  ],

  propertyPurposeOptions: [
    {
      text: 'Investment',
      value: 'Investment',
    },
    {
      text: 'Owner Occupied',
      value: 'Owner Occupied',
    },
  ],

  propertyStatusOptions: [
    {
      text: 'Established',
      value: 'established',
    },
    {
      text: 'Vacant land',
      value: 'vacant land',
    },
    {
      text: 'Turn-key',
      value: 'Turn-key',
    },
    {
      text: 'Construction',
      value: 'construction',
    },
  ],

  valuationBasisOptions: [
    {
      text: 'Applicant Estimate',
      value: 'Applicant Estimate',
    },
    {
      text: 'Registered Valuation',
      value: 'Registered Valuation',
    },
    {
      text: 'Purchase Price',
      value: 'Purchase Price',
    },
  ],
};

const depositSourceOptions = [
  'Savings/Cash', 'Gift',
  'KiwiSaver', 'Home Start Grant',
  'Net Proceeds of Sale', 'Other',
];

const insuranceSectionData = {
  liveOptions: [
    {
      text: 'Yes',
      value: 'yes',
    },
    {
      text: 'No',
      value: 'no',
    },
    {
      text: 'Unsure, but I\'d like to discuss it',
      value: 'unsure',
    },
  ],
  contentOptions: [
    {
      text: 'Yes',
      value: 'yes',
    },
    {
      text: 'No',
      value: 'no',
    },
    {
      text: 'Unsure, but I\'d like to discuss it',
      value: 'unsure',
    },
  ],
};

const dependantsSectionData = {
  yobOptions: [
    'Elder', '2000', '2001', '2002',
    '2003', '2004', '2005', '2006',
    '2007', '2008', '2009', '2010',
    '2011', '2012', '2013', '2014',
    '2015', '2016', '2017', '2018',
    '2019', '2020', '2021',
  ],
};

const estateAssetsSectionData = {
  ownershipOptions: ['Joint', 'Guaranator'],
  purposeOptions: ['Owner Occupied', 'Investment'],
  valueBasisOptions: ['Applicant Estimate', 'Government Valuation', 'Purchase Price', 'Registered Valuation'],
  securityOptions: ['Yes', 'No'],
  mortgagesSecurityOptions: ['Refinance', 'As is', 'The same bank'],
};

export {
  companiesSectionData,
  loanSectionData,
  depositSourceOptions,
  insuranceSectionData,
  dependantsSectionData,
  estateAssetsSectionData,
};
