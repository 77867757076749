var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSection', {
    attrs: {
      "id": _vm.section.key,
      "is-visible": _vm.isSectionVisible,
      "is-modified": _vm.isDataChanged,
      "section": _vm.section
    },
    on: {
      "onSectionSaveClick": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "section",
      fn: function fn() {
        return [_c('v-card-text', {
          staticClass: "mt-4"
        }, [_c('div', {
          staticClass: "mb-5"
        }, [_c('v-radio-group', {
          attrs: {
            "row": true
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_c('h3', [_vm._v(" Do all applicants have adequate insurance cover to protect your family against injury, death, or finance hardship? ")])])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.insurance.live,
            callback: function callback($$v) {
              _vm.$set(_vm.insurance, "live", $$v);
            },
            expression: "insurance.live"
          }
        }, _vm._l(_vm.insuranceSectionData.liveOptions, function (liveItem, idx) {
          return _c('v-radio', {
            key: idx,
            attrs: {
              "value": liveItem.value,
              "disabled": _vm.FIELDS_DISABLED
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function fn() {
                return [_c('div', [_vm._v(_vm._s(liveItem.text))])];
              },
              proxy: true
            }], null, true)
          });
        }), 1), _c('v-textarea', {
          attrs: {
            "outlined": "",
            "label": "Comment",
            "placeholder": "Comment",
            "rows": 4,
            "auto-grow": "",
            "disabled": _vm.FIELDS_DISABLED
          },
          model: {
            value: _vm.insurance.comment_family,
            callback: function callback($$v) {
              _vm.$set(_vm.insurance, "comment_family", $$v);
            },
            expression: "insurance.comment_family"
          }
        }), _c('v-radio-group', {
          attrs: {
            "row": true
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_c('h3', [_vm._v(" Do all applicants have adequate insurance cover to protect your home and contents against accident, damage, or theft? ")])])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.insurance.content,
            callback: function callback($$v) {
              _vm.$set(_vm.insurance, "content", $$v);
            },
            expression: "insurance.content"
          }
        }, _vm._l(_vm.insuranceSectionData.contentOptions, function (contentItem, idx) {
          return _c('v-radio', {
            key: idx,
            attrs: {
              "value": contentItem.value,
              "disabled": _vm.FIELDS_DISABLED
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function fn() {
                return [_c('div', [_vm._v(_vm._s(contentItem.text))])];
              },
              proxy: true
            }], null, true)
          });
        }), 1), _c('v-textarea', {
          attrs: {
            "outlined": "",
            "label": "Comment",
            "placeholder": "Comment",
            "rows": 4,
            "auto-grow": "",
            "disabled": _vm.FIELDS_DISABLED
          },
          model: {
            value: _vm.insurance.comment_home,
            callback: function callback($$v) {
              _vm.$set(_vm.insurance, "comment_home", $$v);
            },
            expression: "insurance.comment_home"
          }
        })], 1)])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }