<template>
  <BaseSection
    :id="section.key"
    :is-visible="isSectionVisible"
    :is-modified="isDataChanged"
    :section="section"
    @onSectionSaveClick="save"
  >
    <template #section>
      <v-card-text class="mt-4">
        <div class="mb-5">
          <v-radio-group
            v-model="insurance.live"
            :row="true"
          >
            <template #label>
              <div>
                <h3>
                  Do all applicants have adequate insurance cover
                  to protect your family against injury, death, or finance hardship?
                </h3>
              </div>
            </template>
            <v-radio
              v-for="(liveItem, idx) in insuranceSectionData.liveOptions"
              :value="liveItem.value"
              :key="idx"
              :disabled="FIELDS_DISABLED"
            >
              <template #label>
                <div>{{ liveItem.text }}</div>
              </template>
            </v-radio>
          </v-radio-group>

          <v-textarea
            outlined
            label="Comment"
            placeholder="Comment"
            :rows="4"
            auto-grow
            v-model="insurance.comment_family"
            :disabled="FIELDS_DISABLED"
          ></v-textarea>

          <v-radio-group
            v-model="insurance.content"
            :row="true"
          >
            <template #label>
              <div>
                <h3>
                  Do all applicants have adequate insurance cover
                  to protect your home and contents against accident, damage, or theft?
                </h3>
              </div>
            </template>
            <v-radio
              v-for="(contentItem, idx) in insuranceSectionData.contentOptions"
              :value="contentItem.value"
              :key="idx"
              :disabled="FIELDS_DISABLED"
            >
              <template #label>
                <div>{{ contentItem.text }}</div>
              </template>
            </v-radio>
          </v-radio-group>

          <v-textarea
            outlined
            label="Comment"
            placeholder="Comment"
            :rows="4"
            auto-grow
            v-model="insurance.comment_home"
            :disabled="FIELDS_DISABLED"
          ></v-textarea>
        </div>
      </v-card-text>
    </template>
  </BaseSection>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';
import { insuranceSectionData } from './dataOptions';

import SectionMixin from '@/mixins/SectionMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';

export default {
  name: 'InsuranceSection',

  components: {
    BaseSection,
  },

  mixins: [SectionMixin],

  props: {
    existedData: {
      type: Object,
      default() {
        return {};
      },
    },

    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      insuranceSectionData,

      isSectionVisible: false,

      initialInsurance: [],
      insurance: [],
    };
  },

  async created() {
    await this.checkIsSectionVisible();

    this.initialInsurance = clone(this.existedData);
    this.insurance = clone(this.existedData);

    this.$emit('componentReady');
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),
  },
};
</script>
